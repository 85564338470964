* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input {
  border: none;
  background: none;
}

/* Spacing */

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex {
  display: flex;
}

.j-center {
  justify-content: center;
}

.a-center {
  align-items: center;
}

.wrap {
  flex-wrap: wrap;
}

.column {
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

/* Typography */

.logo {
  font-size: calc(3rem + 1.2vw);
  font-weight: 600;
  padding-top: 5rem;
}

.headline {
  font-size: calc(2rem + 1.2vw);
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 1rem;
}

.subhead {
  font-size: calc(1.6rem + 0.5vw);
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 3rem;
}

.regular {
  font-size: calc(1.4rem + 0.4vw);
  font-style: normal;
  font-weight: 600;
}

.description-text {
  font-style: normal;
  font-weight: 500;
  font-size: calc(1.2rem + 0.26vw);
  padding: 1rem;
}