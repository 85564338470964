.app {
  height: 100vh;
  overflow: hidden;
  background: #096079;
  min-width: 100%;
}

.header-container {
  height: 20rem;
}

.sun {
  background: #FCFF67;
  width: 31rem;
  height: 31rem;
  border-radius: 50%;
  margin-top: -14rem;
  animation: slideDown 2s 1;
}

.cloud-large {
  width: 22%;
  min-width: 20.5rem;
}

.cloud-med {
  width: 17%;
  min-width: 15rem;
}

.cloud-small {
  width: 12.5%;
  min-width: 12rem;
}

.left1 {
  top: 30%;
  left: 17%;
}

.left2 {
  top: -33%;
  left: 6%;
}

.left3 {
  top: 8rem;
  left: -6.5rem;
}

.left1,
.left2,
.left3 {
  animation: slideInLeft 2.1s 1;
}

.right1 {
  top: 0%;
  right: 15%;
}

.right2 {
  top: -7rem;
  right: -10rem;
}

.right3 {
  top: 11rem;
  right: -1rem;
}

.right1,
.right2,
.right3 {
  animation: slideInRight 2.1s 1;
}

.container-left,
.container-right {
  width: 50%;
}

.container-left {
  padding: 5%;
  animation: fadeIn 2s 1 forwards;
}

.email-input::placeholder {
  color: black;
}

.email-input {
  width: 55%;
  height: 2rem;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  padding: 2rem;
}

.btn {
  background: #C3C800;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  width: 40%;
  height: 4rem;
}

.btn-inside {
  background: #FBFE83;
  border-radius: 5px;
  width: 100%;
  height: 3.7rem;
  top: 0;
  left: 0;
}

.card {
  width: 31%;
  height: 12rem;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
  border-radius: 9px;
}

.signup-container {
  margin-bottom: 5rem;
}

.form {
  width: 100%;
}

.home-img {
  width: 100%;
  min-width: 50rem;
  filter: drop-shadow(0px 35px 4px rgba(0, 0, 0, 0.25));
  animation: hover 4s infinite forwards ease-in-out, fadeIn 2s 1 forwards;
}

.bezier {
  bottom: -2%;
  left: 0%;
  width: 100%;
}


@media screen and (max-width: 1100px) {
  .sun {
    height: 25rem;
    width: 25rem;
  }
}

@media screen and (max-width: 1100px) {
  .container-left {
    width: 55%;
  }

  .container-right {
    width: 45%;
  }
}



@media screen and (max-width: 950px) {

  .container-right {
    display: none;
  }

  .container-left {
    width: 100%;
  }

  .card {
    height: 16rem;
  }

  .headline {
    font-size: calc(2.5rem + 1.2vw);
  }

  .description-text {
    font-size: calc(1.2rem + 0.25vw)
  }

  main {
    margin-top: -2.5rem;
  }

  .left1 {
    top: 25%;
    left: 14vw;
  }

  .left2 {
    top: -25%;
    left: 6%;
  }

  .left3 {
    top: 40%;
    left: -5%;
  }

  .right1 {
    top: 6%;
    right: 12%;
  }

  .right2 {
    top: -20%;
    right: -10%;
  }

  .right3 {
    top: 43%;
    right: -2%;
  }

}

@media screen and (max-width: 800px) {

  .left1 {
    top: 25%;
    left: 5%;
  }

  .left2 {
    top: -25%;
    left: 6%;
  }

  .left3 {
    top: 10%;
    left: -15%;
  }

  .right1 {
    top: 6%;
    right: 4%;
  }

  .right2 {
    top: -20%;
    right: -12%;
  }

  .right3 {
    top: 43%;
    right: -14%;
  }

}

@media screen and (max-width: 640px) {

  .left1 {
    top: 30%;
    left: -8%;
  }

  .left2 {
    top: -20%;
    left: 0%;
  }

  .left3 {
    display: none
  }

  .right1 {
    top: 30%;
    right: -10%;
  }

  .right2 {
    display: none;
  }

  .right3 {
    top: -10%;
    right: 0%;
  }
}

@media screen and (max-width: 500px) {
  .left1 {
    top: 30%;
    left: -20%;
  }

  .left2 {
    top: -18%;
    left: -10%;
  }

  .right1 {
    top: 30%;
    right: -20%;
  }

  .right3 {
    top: -10%;
    right: -15%;
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-400%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(400%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-400%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes hover {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(-4%);
    filter: drop-shadow(0px 55px 4px rgba(0, 0, 0, 0.15));
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}